<template>
  <div class="container-fluid account-setting-page-wrapper">
    <Header :is-hide-menu="true" :is-hide-user="false" class="text-left" />
    <div class="account-setting-body">
      <ProgressStep :isCompany="true" :edit="isEdit" />
      <section class="setting-container">
        <a-form :form="form" @submit="handleSubmit">
          <div class="detail-setting-container">
            <!-- Business Name -->
            <a-form-item class="business-name-container">
              <strong>
                {{ lbl['account-setup-your-business-name'] }}
              </strong>
              <a-input
                id="account_setup_business_name_input"
                v-decorator="[
                  'business_name',
                  {
                    initialValue: business_name,
                    rules: [
                      {
                        required: true,
                        message: '',
                      },
                    ],
                  },
                ]"
                type="text"
                class="form-control w-100"
                @click="handleGTMName()"
              />
            </a-form-item>
            <!-- Business Type -->
            <a-form-item class="business-type-container">
              <strong>
                {{ lbl['account-setup-business-type'] }}
              </strong>
              <a-select
                id="account_setup_business_type_selection"
                v-decorator="[
                  'business_type',
                  {
                    initialValue: business_type,
                    rules: [
                      {
                        required: true,
                        message: '',
                      },
                    ],
                  },
                ]"
                size="large"
                @change="
                  e => {
                    changeBusinessType(e)
                  }
                "
              >
                <a-select-option
                  v-for="(item, index) in optionBusinessType"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <!-- Upload Your Logo -->
          <a-form-item class="upload-logo-container">
            <strong>
              {{ lbl['account-setup-upload-your-logo'] }}
            </strong>
            <span class="sub-logo">
              {{ lbl['account-setup-upload-your-logo-leter'] }}
            </span>
            <div class="">
              <div class="field-upload">
                <div
                  id="account_setup_image_logo_preview"
                  class="box-img"
                  :class="{
                    'box-img-border': isPreview,
                    'none-border': imgPreview,
                  }"
                >
                  <img
                    v-if="!imgPreview"
                    class="w-100"
                    src="@/assets/images/blank-photo.png"
                    alt=""
                  />
                  <img v-else :src="imgPreview" class="w-100 h-100" alt="" />
                </div>
                <div class="box-upload">
                  <div
                    id="account_setup_image_logo_input"
                    class="btn-bg-upload"
                    @click="uploadArtWork($event)"
                  >
                    <input
                      @change="onImgFileChange($event)"
                      ref="uploadImg"
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      hidden
                    />
                    <img
                      src="@/assets/images/accountsetup/account_upload.svg"
                      alt=""
                      width="24"
                      height="24"
                    />
                    <span class="ml-2">
                      {{ lbl['account-setup-upload-image'] }}
                    </span>
                  </div>
                  <div class="box-recommended">
                    <div>
                      {{ lbl['account-setup-the-recommended'] }}
                    </div>
                    <div>
                      {{ lbl['account-setup-upload-file-recommended'] }}
                    </div>
                    <div>
                      {{ sizeUpload }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>
          <!-- Campaign mode -->
          <div>
            <a-form-item class="field-item">
              <strong>{{ lbl['account-setup-campaign-mode'] }}</strong>
              <div class="radio-group-container">
                <a-radio-group
                  v-if="isEdit"
                  id="account_setup_campaign_mode_radio_group"
                  v-model="radioCampaignMode"
                  class="wrapper-radio-custom"
                  @change="changeRadioMode"
                >
                  <a-radio
                    id="account_setup_campaign_mode_point_radio"
                    :disabled="
                      crmPlusRole !== 'Owner' && crmPlusRole !== 'admin'
                    "
                    :value="'point'"
                    class="radio-main"
                    :class="{ active: radioCampaignMode == 'point' }"
                  >
                    <strong>
                      {{ lbl['plan-reward-plan-mode-point'] }}
                    </strong>
                    <div class="mt-10">
                      <span>
                        {{ lbl['plan-reward-plan-mode-point-desc'] }}
                      </span>
                    </div>
                  </a-radio>
                  <a-radio
                    v-if="isPH"
                    id="account_setup_campaign_mode_stamp_radio"
                    :disabled="
                      crmPlusRole !== 'Owner' && crmPlusRole !== 'admin'
                    "
                    :value="'stamp'"
                    class="radio-main"
                    :class="{ active: radioCampaignMode == 'stamp' }"
                  >
                    <strong>
                      {{ lbl['plan-reward-plan-mode-stamp'] }}
                    </strong>
                    <div class="mt-10">
                      <span>
                        {{ lbl['plan-reward-plan-mode-stamp-desc'] }}
                      </span>
                    </div>
                  </a-radio>
                </a-radio-group>
                <a-radio-group
                  v-else
                  id="account_setup_campaign_mode_radio_group"
                  v-model="radioCampaignMode"
                  class="wrapper-radio-custom"
                  @change="changeRadioMode"
                >
                  <a-radio
                    id="account_setup_campaign_mode_point_radio"
                    :value="'point'"
                    class="radio-main"
                    :class="{ active: radioCampaignMode == 'point' }"
                  >
                    {{ lbl['plan-reward-plan-mode-point'] }}
                    <div class="mt-10">
                      <span>
                        {{ lbl['plan-reward-plan-mode-point-desc'] }}
                      </span>
                    </div>
                  </a-radio>
                  <a-radio
                    v-if="isPH"
                    id="account_setup_campaign_mode_stamp_radio"
                    :value="'stamp'"
                    class="radio-main"
                    :class="{ active: radioCampaignMode == 'stamp' }"
                  >
                    {{ lbl['plan-reward-plan-mode-stamp'] }}
                    <div class="mt-10">
                      <span>
                        {{ lbl['plan-reward-plan-mode-stamp-desc'] }}
                      </span>
                    </div>
                  </a-radio>
                </a-radio-group>
              </div>
            </a-form-item>
          </div>
          <!-- Consumer login type -->
          <div v-if="!isHideLoginTypeHeader" class="radio-group-container">
            <a-form-item class="field-item">
              <strong>
                {{ lbl['account-setup-consumer-login-type'] }}
              </strong>
              <div
                v-for="(group, i) in loginTypeList"
                class="radio-group-container"
                :key="i"
              >
                <strong v-if="group.Details.length">
                  {{ checkLocalTH ? group.Header : group.HeaderEN }}
                </strong>

                <div v-if="group.Details.length" class="wrapper-radio-custom">
                  <div
                    v-for="(item, index) in group.Details"
                    :key="index"
                    class="radio-main"
                    :class="{
                      active: radioLoginType == item.Id,
                    }"
                    @click="radioLoginType = item.Id"
                  >
                    <a-tooltip>
                      <template slot="title">
                        {{ checkLocalTH ? item.ToolTip : item.ToolTipEN }}
                      </template>
                      <div
                        :id="item.Name"
                        :disabled="isEdit || isPH"
                        class="login-type-card"
                      >
                        <img
                          :src="
                            require(`@/assets/images/loginTypeIcon/login_type_id_${item.Id}.svg`)
                          "
                          alt="login type icon"
                          class="logintype-icon"
                        />
                        <strong class="login-type-title">
                          {{ checkLocalTH ? item.Name : item.NameEN }}
                        </strong>
                        <span v-if="item.Recommend" class="sub-description">
                          {{ lbl['account-setup-login-type-recommend'] }}
                        </span>
                        <div class="mt-2 login-type-detail">
                          <span>
                            {{ checkLocalTH ? item.Detail : item.DetailEN }}
                          </span>
                        </div>
                      </div>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </a-form-item>
          </div>
          <div class="noted">
            *{{ lbl['account-setup-change-store-mode-guide'] }}
          </div>

          <!-- Footer Button -->
          <div class="footer-wraper">
            <b-button
              id="account_setup_submit_button"
              variant="warning"
              class="btn-submit float-right button"
              html-type="submit"
              ref="submit"
              type="submit"
              :disabled="formState"
            >
              {{ lbl['account-setup-save-and-next'] }}
            </b-button>
          </div>
        </a-form>
      </section>
    </div>
    <CampaignMode
      :show="isModalMode"
      :otpDetails="otpDetails"
      :getOTP="getOTP"
      :confirmOTP="confirmOTP"
      :gaback="gaback"
      @changeCampaignMode="changeCampaignMode($event)"
    />
    <EmailModal
      :show="isEmailModal"
      @ChamgeEmailmodal="ChamgeEmailmodal($event)"
    />
    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
      :disabledConfirm="!disabledBtn"
    >
      <div class="telcontainer mt-3" v-if="OwnerContactNumber">
        <label>{{ lbl['otpentermobilenumber'] }}</label>
        <div class="inputcontainer">
          <img src="@/assets/images/iPhone-X.svg" alt="" />
          <a-input
            v-model="phone"
            :maxLength="maxlength"
            @input="checkInput($event)"
            :placeholder="lbl['otpentermobilenumberPlaceHolder']"
            :class="{ error: !phoneState }"
            allow-clear
          />
        </div>
        <div class="text-danger" v-if="!phoneState && !!phone">
          {{ lbl['worngphonenumber'] }}
        </div>
      </div>
    </ConfirmModal>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Cache from '@/helper/Cache.js'
import InfiniteHelper from '@/helper/InfiniteHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import CampaignMode from '@/module/Settings/AccountSetup/modal/CampaignMode'
import EmailModal from '@/module/Settings/AccountSetup/modal/EmailModal'
import fileReader from '@/helper/fileReader'
import Account from '@/helper/AccountHelper.js'
import BzbsAuth from '@/core/Account/service/BzbsAuth'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import Utility from '@/helper/Utility.js'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import ProgressStep from '@/module/Settings/components/ProgressStep'

export default {
  name: 'AccountSetting2',
  mixins: [Mixin],
  components: {
    Header,
    CampaignMode,
    ConfirmModal,
    AlertModal,
    EmailModal,
    ProgressStep,
  },
  data: function () {
    return {
      optionBusinessType: [],
      radioCampaignMode: 'point',
      radioLoginType: null,
      isPH: false,
      isTH: false,
      imgPreview: '',
      isPreview: false,
      sizeUpload: '',
      business_name: '',
      business_type: '',
      isEdit: false,
      isModalMode: false,
      isEmailModal: false,
      storeType: '',
      appId: '',
      brandId: '',
      crmPlusRole: '',
      params: {},
      params_reward: {},
      util: Utility,
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        confirm: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      maxlength: 10,
      OwnerContactNumber: null,
      phone: '',
      LineMessageChannelId: '',
      otpDetails: {},
      timeinterval: null,
      baseradioCampaignMode: null,
      isOTPConfirm: true,
      settingSuccess: false,
      loginTypeList: [],
      isHideLoginTypeHeader: false,
    }
  },
  computed: {
    disabledBtn() {
      if (this.phone == '' && this.OwnerContactNumber) {
        return false
      } else {
        return true
      }
    },
    phoneState() {
      if (this.phone == '') return true
      if (!this.OwnerContactNumber) return true
      return this.phone == this.OwnerContactNumber
    },
    formState() {
      return !this.isOTPConfirm
    },
    checkLocalTH() {
      if (Cache.get('Locale') == 'th') {
        return true
      } else {
        return false
      }
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'account-setting',
    })
  },
  created() {
    this.handleFooter(true)
    if (Vue.bzbsConfig.businessRule.webFor.PH) {
      this.isPH = true
    }
    if (Vue.bzbsConfig.businessRule.webFor.TH) {
      this.isTH = true
    }
    this.googleTagManager(
      'setting_account_page',
      'setting_account',
      'view_setting',
      null,
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.sizeUpload = this.lbl[
        'account-setup-upload-size-recommended'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      this.maxlength = Vue.bzbsConfig.businessRule.max.phoneLength
      this.getLoginTypeList()
      this.getBusinessType()
      this.getCRMPlusProfile()
    },
    getLoginTypeList() {
      return new Promise((resolve, reject) => {
        BzbsProfile.getLoginTypeList()
          .then(response => {
            console.log(response.data)
            this.loginTypeList = Account.filterLoginTypeSOB(response.data)
            if (
              this.loginTypeList &&
              this.loginTypeList.every(i => i.Details.length == 0)
            )
              this.isHideLoginTypeHeader = true
            resolve(response)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getLoginTypeList error', error)
            this.isHideLoginTypeHeader = true
            reject(error)
          })
      })
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.handleLoading(false)
            console.log(res)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)

            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      }
    },
    genDataProfile(data) {
      this.storeType = data.StoreType
      this.imgPreview = data.LogoUrl + '?v=' + new Date().getTime()
      this.business_name = data.BusinessName
      this.business_type = data.BusinessType

      if (this.isUseFacebook && this.isPH) {
        this.radioLoginType = 5
      } else {
        if (this.isPH) {
          this.radioLoginType = 2
        } else {
          this.radioLoginType = data.LoginType
            ? Utility.mapLoginTypeId(data.LoginType.toLowerCase())
            : 1
        }
      }
      this.radioCampaignMode = data.StoreType ? data.StoreType : 'point'
      this.baseradioCampaignMode = data.StoreType ? data.StoreType : 'point'
      this.params.agencyId = data.SponsorId
      this.params_reward.agencyId = data.SponsorId
      this.business_name ? (this.isEdit = true) : (this.isEdit = false)
      this.OwnerContactNumber = data.OwnerContactNumber
      this.LineMessageChannelId = data.LineMessageChannelId

      if (data.Stores) {
        let branch = _.map(data.Stores, e => {
          return {
            id: e.ID,
            name: e.Name,
            contact_number: e.ContactNumber,
            latitude: e.Latitude,
            longtitude: e.Longtitude,
            isheadoffice: e.IsHeadOffice,
          }
        })

        this.params.branch = JSON.stringify(branch)
      }

      this.brandId = data.EwalletBrandId
      this.appId = data.AppId
      var CRMUserCookie = Cache.get('CRMUser')
      CRMUserCookie.AppId = data.AppId
      Cache.set('CRMUser', CRMUserCookie)
      this.crmPlusRole = Cache.get('CRMUser').CrmPlusRole
    },
    getOTP() {
      this.handleLoading(true)
      let params = {
        contact_number: this.phone,
        app_id: Vue.bzbsConfig.client_id,
        channel: 'update',
      }
      BzbsAuth.apiRequestOTP(params)
        .then(res => {
          this.handleLoading(false)
          this.otpDetails = res.data
          this.isModalMode = true
          this.countDownTimer()
        })
        .catch(() => {
          this.handleLoading(false)
        })
    },
    confirmOTP(otpcode) {
      this.handleLoading(true)
      let params = {
        contact_number: this.phone,
        app_id: Vue.bzbsConfig.client_id,
        channel: 'update',
        otp: otpcode,
        refcode: this.otpDetails.refcode,
      }
      BzbsAuth.apiConfirmOTP(params)
        .then(() => {
          this.handleLoading(false)
          this.isModalMode = false
          this.isOTPConfirm = true
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            '',
            'success',
            true,
          )
        })
        .catch(error => {
          this.handleLoading(false)
          this.isOTPConfirm = false
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message,
            'error',
            true,
          )
        })
    },
    checkInput(e) {
      this.phone = e.target.value.replace(/[^0-9]/g, '')
    },
    gaback() {
      this.isModalMode = false
      this.confirmModalAction(
        'Change campaign mode ?',
        'By changeing campaign mode will be effect to all of your consumer.',
        'warning',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.getOTP()
          this.confirmModalSetting.isOpenModal = false
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.radioCampaignMode = this.baseradioCampaignMode
        },
      )
    },
    countDownTimer() {
      if (this.timeinterval) clearInterval(this.timeinterval)
      this.timeinterval = setInterval(() => {
        if (this.otpDetails.expireinseconds > 0) {
          this.otpDetails.expireinseconds -= 1
        } else {
          clearInterval(this.timeinterval)
        }
      }, 1000)
    },
    isJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getBusinessType() {
      this.optionBusinessType = InfiniteHelper.businessTypeList()
    },
    changeBusinessType(e) {
      console.log('BusinessType : ', e)
      this.googleTagManager(
        'setting_account_page',
        'setting_account_menu',
        'click_business_type',
        null,
        'on click',
      )
      this.business_type = e
    },
    uploadArtWork() {
      this.googleTagManager(
        'setting_account_page',
        'setting_account_menu',
        'click_upload_logo',
        null,
        'on click',
      )
      this.$refs.uploadImg.value = null
      this.$refs.uploadImg.click()
    },
    onImgFileChange(event) {
      var image = event.target.files[0]
      var allowType = ['image/jpeg', 'image/jpg', 'image/png']
      var maxSize = 1024 * 1024 * Vue.bzbsConfig.businessRule.max.photoSize
      var isCorrectType = allowType.includes(image.type)
      var isCorrectSize = image.size <= maxSize
      var lblmaximumConfig = this.lbl[
        'image-recommended-message-setting'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (isCorrectType && isCorrectSize && this.imgPreview) {
        this.isPreview = false
        var afterDot = image.name.substr(0, image.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([image], newFileName, {
          type: image.type,
        })
        this.imgPreview = URL.createObjectURL(resultFile)
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    changeRadioMode(e) {
      this.googleTagManager(
        'setting_account_page',
        'setting_account_menu',
        'click_campaign_mode',
        null,
        'on click',
      )
      if (
        this.baseradioCampaignMode !== e.target.value &&
        this.storeType != null
      ) {
        this.isOTPConfirm = false
      } else {
        this.isOTPConfirm = true
      }
      this.radioCampaignMode = e.target.value
      if (this.isEdit) {
        if (!this.isPH) {
          if (this.OwnerContactNumber) {
            if (this.baseradioCampaignMode != e.target.value) {
              this.confirmModalAction(
                this.lbl['otptitle'],
                this.lbl['otpsubtitle'],
                'warning',
                this.lbl['confirm-box-cancel-button'],
                true,
                () => {
                  this.getOTP()
                  this.confirmModalSetting.isOpenModal = false
                },
                () => {
                  this.isOTPConfirm = true
                  this.radioCampaignMode = this.baseradioCampaignMode
                  this.confirmModalSetting.isOpenModal = false
                },
              )
            }
          } else {
            this.confirmModalAction(
              this.lbl['otpwarning'],
              this.lbl['otpwarningtext'],
              'warning',
              this.lbl['confirm-box-cancel-button'],
              true,
              () => {
                this.$router.push({ name: 'UserPermission' })
                this.confirmModalSetting.isOpenModal = false
              },
              () => {
                this.confirmModalSetting.isOpenModal = false
              },
            )
          }
        } else {
          this.isEmailModal = true
        }
      }
    },
    changeCampaignMode(res) {
      console.log('changeCampaignMode : ', res)
      this.isModalMode = false
      if (res) {
        console.log('changeCampaignMode true')
        // this.storeType = this.radioCampaignMode
      } else {
        console.log('changeCampaignMode false')
        // this.radioCampaignMode = this.storeType
      }
      console.log('storeType :: ', this.storeType)
    },
    ChamgeEmailmodal(res) {
      console.log('ChamgeEmailmodal : ', res)
      this.isEmailModal = false
      if (res) {
        console.log('ChamgeEmailmodal true')
        this.storeType = this.radioCampaignMode
        this.isOTPConfirm = false
      } else {
        console.log('ChamgeEmailmodal false')
        this.radioCampaignMode = this.storeType
        this.isOTPConfirm = true
      }
      console.log('storeType :: ', this.storeType)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.googleTagManager(
        'setting_account_page',
        'setting_account_menu',
        'click_next_account_setting',
        null,
        'on click',
      )
      if (!this.imgPreview) {
        this.isPreview = true
      }
      this.form.validateFields((err, values) => {
        if (!err && this.imgPreview) {
          console.log(values)
          fileReader.getBaseImage(this.imgPreview).then(item => {
            this.params.logo = fileReader.blobToFile(item, 'LogoUrl')
            this.params.business_name = values.business_name
            this.params.business_type = values.business_type
            if (!this.isEdit) {
              this.params.loginTypeId = this.radioLoginType
            }

            this.handleLoading(true)
            if (this.storeType != this.radioCampaignMode) {
              return new Promise(resolve => {
                BzbsProfile.postAccountSetting(this.params)
                  .then(res => {
                    this.setLogoUrl(res)
                    this.postReward()
                    this.handleLoading(false)
                    resolve(res.data)
                  })
                  .catch(error => {
                    this.handleLoading(false)
                    this.alertModalAction(
                      this.lbl['alert-box-error-header'],
                      error.response.data.error.message,
                      'error',
                      true,
                    )
                    resolve(error)
                  })
              })
            } else {
              return new Promise(resolve => {
                BzbsProfile.postAccountSetting(this.params)
                  .then(res => {
                    this.setLogoUrl(res)

                    this.$router.push({
                      name: 'ConnectSetting2',
                      query: { setting: true },
                    })

                    this.handleLoading(false)
                    resolve(res.data)
                  })
                  .catch(error => {
                    this.handleLoading(false)
                    this.alertModalAction(
                      this.lbl['alert-box-error-header'],
                      error.response.data.error.message,
                      'error',
                      true,
                    )
                    resolve(error)
                  })
              })
            }
          })
        } else {
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            this.lbl['alert-box-error-fill-info'],
            'error',
            true,
          )
        }
      })
    },
    setLogoUrl(res) {
      console.log('setLogoUrl : ', res.data.store.LogoUrl)
      if (res.data.store.LogoUrl) {
        var img = res.data.store.LogoUrl + '?v=' + new Date().getTime()
        Account.updateLogoUrl(img)
      }
    },
    postReward() {
      console.log('postReward')
      this.params_reward.type = this.radioCampaignMode
      return new Promise(resolve => {
        BzbsProfile.postReward(this.params_reward)
          .then(res => {
            if (res.data.Success) {
              this.$router.push({
                name: 'ConnectSetting2',
              })
            }
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    handleGTMName() {
      console.log('handleGTMName')
      this.googleTagManager(
        'setting_account_page',
        'setting_account_menu',
        'click_business_name',
        null,
        'on click',
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';
.account-setting-page-wrapper {
  padding-bottom: 130px !important;
  text-align: -webkit-center;
  .account-setting-body {
    width: 90%;
    max-width: $max-width-tablet;
    text-align: left;

    .setting-container {
      background-color: #ffffff;
      margin-top: 30px;
      padding: 30px;

      .detail-setting-container {
        widows: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        .business-name-container {
          min-width: 120px;
          flex: 2;
        }
        .business-type-container {
          min-width: 120px;
          flex: 1;
        }
      }
      .upload-logo-container {
        .field-upload {
          display: flex;
          .box-img {
            position: relative;
            border: 1px solid $color-grey-c1;
            width: 200px;
            height: 200px;
            margin-bottom: 20px;
            margin-right: 20px;

            &.none-border {
              border: initial;
            }

            &.box-img-border {
              border: 1px solid $color-red-f5;
            }
          }
          .box-upload {
            .btn-bg-upload {
              border: solid 1px $color-orange-2;
              background-color: $color-white;
              padding: 0px 15px;
              border-radius: 8px;
              font-size: 14px;
              font-weight: 500;
              color: $color-orange-2;
              cursor: pointer;
              width: 160px;
              margin-bottom: 10px;
            }
            .box-recommended {
              line-height: 1.65;
              font-size: 12px;
              color: $color-grey-a6;
            }
          }
        }
      }
    }
  }

  .footer-wraper {
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 40px;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    .button {
      width: 20%;
      min-width: 100px;
      padding: 16px;
      color: #ffffff;
      background-color: $primary-hover;
    }
    .btn-warning:disabled {
      color: #fff !important;
      background-color: #c4c4c4 !important;
      border-color: #c4c4c4 !important;
    }
  }

  .radio-group-container {
    margin-top: 16px;
    .wrapper-radio-custom {
      width: 80%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;

      .radio-main {
        cursor: pointer;
        flex: 1;
        min-width: 100px;
        padding: 16px;
        border-radius: 4px;
        border: solid 1px #ffffff;
        background-color: #fafafa;
        margin: 0;
        white-space: normal;
        color: $color-grey-75;

        .mt-10 {
          margin-top: 10px;
        }
        .sub-description {
          font-size: 12px;
          color: $text-light-gray;
        }
        strong {
          color: $color-grey-42;
          margin-bottom: 10px;
        }
        .login-type-title,
        .login-type-detail {
          white-space: wrap;
          line-height: 2;
        }
      }
      .active {
        border: solid 1px $primary;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
  .noted {
    color: $color-red-52;
  }
  .notice {
    color: $color-red-52;
    text-align: left;
  }
  .telcontainer {
    .ant-input {
      padding-left: 40px;
    }
    .error {
      .ant-input {
        border: 1px solid red;
      }
    }
  }
}
.text-left {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.telcontainer {
  text-align: left;
  label {
    font-size: 14px;
    font-weight: 500;
  }
  .inputcontainer {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}
.sub-logo {
  color: #a6a6a6;
  font-size: 12px;
}
</style>
<style lang="scss">
@import '../styles/accountsetup_main.scss';
.telcontainer {
  .ant-input {
    padding-left: 40px;
  }
  .error {
    .ant-input {
      border: 1px solid red;
    }
  }
}

.login-type-card {
  .logintype-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
}
</style>
